$module: 'qoc-system';
.#{$module} {
  height: 100%;
  width: 100%;

  &__loading {
    h3 {
      text-align: center;
      color: white;
    }

    align-items: center;
    justify-content: center;
    background: rgba(#000, 0.8);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
  }

  &__children {
    height: 100%;
    width: 100%;
  }
}
